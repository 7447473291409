<template lang="pug">
.campaign-edit-dialog
  CreateEditDialog(
    :item="committee"
    title="Update Committee"
    :isOpen="isOpen"
    @close="$emit('close')"
    @submit-form="saveCommittee"
  )
    template( 
      v-slot:form="{ clone, rules }"
    )
      .d-flex.justify-content-between.gap-8
        v-text-field(
          outlined
          dense
          label="Committee Name"
          v-model="clone.name"
          :rules="[rules.required]"
        )

      AddressForm(
        :rules="rules"
        :clone="clone"
        addressKey="address"
      )
</template>

<script>
import AddressForm from '@/components/Address.Form'
import CreateEditDialog from '@/components/Dialogs/CreateEdit.Dialog'

export default {
  name: 'CommitteeEditDialog',

  components: {
    AddressForm,
    CreateEditDialog
  },

  props: {
    title: {
      type: String
    },
    isOpen: Boolean,
    item: {
      type: Object,
      required: false,
      default: null
    }
  },

  setup(props, { emit, root: { $FeathersVuex, $snackSuccess, $snackError } }) {
    const { Organization } = $FeathersVuex.api
    const committee = new Organization(props.item)

    const saveCommittee = async ({ save, clone }) => {
      try {
        delete clone.short;

        const response = await save()
        const message = 'Committee saved';
        $snackSuccess(message)
        emit('close', response)
      } catch (e) {
        $snackError('Error saving the Committee.');
      }
    }

    return {
      committee,
      saveCommittee
    }
  }
}
</script>

<style>

</style>