<template lang="pug">
div( class="w-full px-8 xl:px-0 xl:w-8/12 2xl:w-1/2 mx-auto" v-if="candidate" )
  v-breadcrumbs(
    class="ml-0 pl-0"
    :items="breadcrumbs"
    v-if="isOrganization"
  )

  v-tabs.mb-8.shadow.rounded-tr-lg.rounded-tl-lg.overflow-hidden(
    :class="{ 'mt-16' : !isOrganization }"
    grow
    v-model="tab"
  )
    v-tab Candidate
    v-tab Campaign
    v-tab( v-if="isOrganization" ) Committee

    v-tab-item.border-t
      .pa-6.rounded-b.shadow
        .mt-4.text-sm.font-medium.text-gray-500.relative Associated Org Types
        v-divider.mb-6

        .d-flex.align-top
          .d-flex.flex-column.flex-grow-1
            .flex-grow-1.mt-1.text-sm.text-gray-900(
              v-for="(selected, idx) of organizationTypes"
              :key="selected.id"
            )
              span( v-if="organizationTypes.length > 1" ).text-gray-400.text-xs.italic {{ idx + 1 }}.
              .inline-block(
                :class="{ 'ml-2': organizationTypes.length > 1 }"
              ) {{ selected.name }}

          OrgTypeSelect(
            v-if="!isPending"
            :viewModel="{ activator: 'button' }"
            v-model="organizationTypes"
            @save="updateOrgIds"
            context="update"
          )

        .group
          .mt-8.text-sm.font-medium.text-gray-500.relative Candidate
            v-btn(
              color="primary"
              small
              depressed
              @click="openCandidateEdit = true"
              class="opacity-70 group-hover:opacity-100 absolute right-0 top-8 z-10"
            )
              | update
          v-divider.mb-6
          dl(class='sm:grid-cols-2 grid grid-cols-1 gap-x-4 gap-y-8 relative')
            div(class='sm:col-span-1')
              dt.text-sm.font-medium.text-gray-500 Full name
              dd.mt-1.text-sm.text-gray-900 {{ candidate.fullName }}

            div(class='sm:col-span-1')
              dt.text-sm.font-medium.text-gray-500 Email
              dd.mt-1.text-sm.text-gray-900
                span( v-if="candidate.email" ) {{ candidate.email }}
                span( v-else class="text-gray-400" ) Not provided

            div(class='sm:col-span-1')
              dt.text-sm.font-medium.text-gray-500 Phone
              dd.mt-1.text-sm.text-gray-900
                span( v-if="candidate.phone" ) {{ candidate.phone }}
                span( v-else class="text-gray-400" ) Not provided

            div(class='sm:col-span-1')
              dt.text-sm.font-medium.text-gray-500 Address
              dd.mt-1.text-sm.text-gray-900 {{ candidate.fullAddress }}

          .mt-8.text-sm.font-medium.text-gray-500 Treasurer
          v-divider.mb-6

          dl(class='sm:grid-cols-2 grid grid-cols-1 gap-x-4 gap-y-8')
            div(class='sm:col-span-1')
              dt.text-sm.font-medium.text-gray-500 Full name
              dd.mt-1.text-sm.text-gray-900 {{ candidate.fullTreasurerName }}

            div(class='sm:col-span-1')
              dt.text-sm.font-medium.text-gray-500 Address
              dd.mt-1.text-sm.text-gray-900 {{ candidate.fullTreasurerAddress }}

    v-tab-item.group.border-t
      v-btn(
        color="primary"
        small
        depressed
        @click="openCampaignEdit = true"
        class="opacity-70 group-hover:opacity-100 absolute right-3 top-3"
      )
        | update
      .pa-6.rounded-b.shadow
        dl(class='sm:grid-cols-2 grid grid-cols-1 gap-x-4 gap-y-8')
          div(class='sm:col-span-1')
            dt.text-sm.font-medium.text-gray-500 Chamber
            dd.mt-1.text-sm.text-gray-900 {{ candidate.campaign.formattedChamber }}

          div(class='sm:col-span-1')
            dt.text-sm.font-medium.text-gray-500 District
            dd.mt-1.text-sm.text-gray-900 {{ candidate.campaign.districtNumber }}

          div(class='sm:col-span-1')
            dt.text-sm.font-medium.text-gray-500 Support Level
            dd.mt-1.text-sm.text-gray-900 {{ candidate.campaign.supportLevel | capitalize }}

          div(class='sm:col-span-1')
            dt.text-sm.font-medium.text-gray-500 Party
            dd.mt-1.text-sm.text-gray-900 {{ candidate.campaign.party | capitalize }}

          div(class='sm:col-span-2')
            dt.text-sm.font-medium.text-gray-500 Budget
            dd.mt-1.text-sm.text-gray-900
              | {{ candidate.campaign.budget | toCurrency }}

          div(class='sm:col-span-1')
            dt.text-sm.font-medium.text-gray-500 Primary Election
            dd.mt-1.text-sm.text-gray-900
              | {{ $day(candidate.campaign.primaryElectionDate).format(formats.DATE) }}

          div(class='sm:col-span-1')
            dt.text-sm.font-medium.text-gray-500 General Election
            dd.mt-1.text-sm.text-gray-900
              | {{ $day(candidate.campaign.generalElectionDate).format(formats.DATE)  }}

    v-tab-item.group.border-t
      v-btn(
        color="primary"
        small
        depressed
        @click="openCommitteeEdit = true"
        class="opacity-70 group-hover:opacity-100 absolute right-3 top-3"
      )
        | update

      .pa-6.rounded-b.shadow( v-if="!isPending && isOrganization" )
        dl(class='sm:grid-cols-2 grid grid-cols-1 gap-x-4 gap-y-8')
          div(class='sm:col-span-1')
            dt.text-sm.font-medium.text-gray-500 Committee
            dd.mt-1.text-sm.text-gray-900 {{ candidate.committee.name }}

          div(class='sm:col-span-1')
            dt.text-sm.font-medium.text-gray-500 Address
            dd.mt-1.text-sm.text-gray-900
              div {{ candidate.fullCommitteeAddress }}

    v-tab-item
      v-btn(
        color="primary"
        small
        depressed
        @click="openCommitteeEdit = true"
        class="opacity-70 group-hover:opacity-100 absolute right-3 top-3"
      )
        | update

      .pa-6.border.rounded-b.shadow( v-if="!isPending && isOrganization" )
        dl(class='sm:grid-cols-2 grid grid-cols-1 gap-x-4 gap-y-8')
          div(class='sm:col-span-1')
            dt.text-sm.font-medium.text-gray-500 Committee
            dd.mt-1.text-sm.text-gray-900 {{ candidate.committee.name }}

          div(class='sm:col-span-1')
            dt.text-sm.font-medium.text-gray-500 Address
            dd.mt-1.text-sm.text-gray-900
              div {{ candidate.committee.address.street }}, {{ candidate.committee.address.city }},
                | {{ candidate.committee.short }} {{ candidate.committee.address.zipCode }}

    .dialogs-portal
      CandidateEditDialog(
        :isOpen="openCandidateEdit"
        @close="onCandidateClose"
        :item="candidate"
        v-if="openCandidateEdit"
      )

      CampaignEditDialog(
        :isOpen="openCampaignEdit"
        @close="onCandidateClose"
        v-if="openCampaignEdit"
        :item="candidate.campaign"
      )

      CommitteeEditDialog(
        :isOpen="openCommitteeEdit"
        @close="onCandidateClose"
        :item="candidate.committee"
        v-if="openCommitteeEdit"
      )

  v-data-table.border.shadow(
    v-if="isOrganization"
    must-sort
    :items="expenses"
    :loading="expensesPending"
    :headers="headers"
    :server-items-length="!expensesPending && pagination ? pagination.total : 0"
    :options.sync="options"
    no-data-text="No approved expenses found"
  )
    template(
      v-slot:item.invoiceDate="{ item: expense }"
    )
      | {{ $day(expense.invoiceDate).format(formats.DATE_SHORT) }}
    template(
      v-slot:item.candidate.lastName="{ item: expense }"
    ) {{ expense.candidate.fullName }}
    template(
      v-slot:item.candidate.lastName="{ item: expense }"
    )
      div( v-if="expense && expense.candidate" )
        span {{ expense.candidate.fullName }}
    template(
      v-slot:item.amount="{ item }"
    )
      h6 {{ item.formattedMoney }}

    template(
      v-slot:item.status="{ item: expense }"
    )
      ExpenseChip( :expense="expense" )
    template( v-slot:body.append )
      tr.bg-gray-100( v-if="!expensesPending" )
        td
          span Sum
        td( :colspan="2" )
        td( :colspan="2" ).text-end
          span.font-bold {{ $sumFmt }}
          span &nbsp; / {{ candidate.campaign.budget | toCurrency(0) }}
          div( style="line-height: 9px; color: #AAA; font-size: 11px; text-align: right" ) BUDGET
  </template>

<script>
import { isOrganization } from '@/helpers/identityHelper'
import { formats } from '@/helpers/data'
import { computed, ref } from '@vue/composition-api'
import { chambers, supportLevels, parties } from '@/helpers/campaignData'
import { useGet } from 'feathers-vuex'
import useDataTableFind from '@/helpers/useDataTableFind'
import FormWrapper from '@/components/FormWrapper'
import AddressForm from '@/components/Address.Form';
import DatePickerDialog from '@/components/DatePicker.Dialog'
import CandidateEditDialog from '@/views/candidates/Candidate.Edit.Dialog'
import CampaignEditDialog from '@/views/campaigns/Campaign.Edit.Dialog'
import CommitteeEditDialog from '@/views/committees/Committee.Edit.Dialog'
import ExpenseChip from './../expenses/Expense.Chip';
import OrgTypeSelect from '@/views/organization-types/OrgType.Select.Dialog'

export default {
  name: 'CandidateEdit',

  components: {
    FormWrapper,
    AddressForm,
    DatePickerDialog,
    CandidateEditDialog,
    CampaignEditDialog,
    CommitteeEditDialog,
    ExpenseChip,
    OrgTypeSelect
  },

  setup(props, { root: { $route, $FeathersVuex, $snackSuccess } }) {
    const id = parseInt($route.params.id);
    const { Candidate, CandidateOrganizationType } = $FeathersVuex.api;
    const eagerQuery = isOrganization
      ? "[state, campaign, committee(stateInclude), treasurerState, orgTypes]"
      : "[state, campaign]"
    const { item: candidate, isPending, get } = useGet({
      model: Candidate,
      id,
      params: {
        query: {
          $eager: eagerQuery
        }
      }
    });
    const onCandidateClose = async () => {
      await get(
        id,
        {
          query: {
            $eager: '[state, treasurerState]'
          }
        })
      openCandidateEdit.value = false
      openCommitteeEdit.value = false
      openCampaignEdit.value = false
    }
    const organizationTypes = computed({
      get() {
        if (candidate && !isPending.value) {
          return candidate.value.orgTypes;
        }
      },
      set (newItems) {
        selectedOrgTypes.value = newItems
      }
    })
    const selectedOrgTypes = ref(organizationTypes.value)
    const breadcrumbs = computed(() => {
      return [{
        text: 'Candidates',
        link: true,
        disabled: false,
        exact: true,
        to: '/candidates'
      },
      {
        text: candidate.value.fullName,
        disabled: true
      }]
    })

    const headers = [
      {
        text: 'Status',
        value: 'status',
        width: '150'
      },
      {
        text: 'Invoice',
        value: 'invoiceNumber',
        width: '100',
        sortable: false
      },
      {
        text: 'Candidate',
        value: 'candidate.lastName'
      },
      {
        text: 'Date',
        align: 'end',
        value: 'invoiceDate'
      },
      {
        text: 'Amount',
        value: 'amount',
        align: 'end',
        sortable: true
      }
    ]

    const filter = computed(() => {
      const filter = {
        $eager: '[vendor, candidate]',
        candidateId: id,
        status: {
          $in: ['submitted', 'approved', 'completed']
        },
        $sum: [
          'amount',
          'sum',
          { candidateId: id, status: ['submitted', 'approved', 'completed'] }
        ]
      }
      return filter
    })

    const {
      items: expenses,
      isPending: expensesPending,
      pagination,
      sum,
      options,
      result
    } = useDataTableFind(
      'expenses',
      headers,
      filter,
      { sortBy: ['invoiceDate'] }
    )
    const $sumFmt = computed(() => result.value ? result.value.$sum.fmt : 0)

    const updateOrgIds = async (ids) => {
      try {
        const candidateId = parseInt(id)
        const candidateOrgTypes = new CandidateOrganizationType({ candidateId, organizationTypeId: ids })
        await candidateOrgTypes.create()
        await get(id, {
          query: {
            $eager: eagerQuery
          }
        })

        $snackSuccess('Org types updated')
      } catch (e) {
        console.error(e)
      }
    }

    const tab = ref('candidate')
    const openCandidateEdit = ref(false);
    const openCampaignEdit = ref(false);
    const openCommitteeEdit = ref(false);

    return {
      onCandidateClose: onCandidateClose,

      candidate,
      breadcrumbs,
      organizationTypes,
      selectedOrgTypes,
      updateOrgIds,

      // dailogs
      openCandidateEdit,
      openCampaignEdit,
      openCommitteeEdit,

      expenses,
      options,
      pagination,
      expensesPending,
      headers,
      sum,
      $sumFmt,

      chambers,
      supportLevels,
      parties,

      isPending,
      formats,

      isOrganization,
      tab
    }
  }
}
</script>